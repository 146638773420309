export function getFeatureName(feature: string) {
  switch (feature) {
    case 'person_lookup':
      return 'Person Lookup';
    case 'phone_lookup':
      return 'Phone Lookup';
    case 'email_lookup':
      return 'Email Lookup';
    case 'address_lookup':
      return 'Address Lookup';
    case 'company_lookup':
      return 'Company Data Checks';
    case 'vin_lookup':
      return 'VIN Lookup';
    case 'criminal_records':
      return 'Criminal Records';
    case 'social_networks':
      return 'Social Networks';
    case 'web_search':
      return 'Web Search';
    case 'messengers':
      return 'Messengers';
    case 'sex_offenders':
      return 'Sex Offenders';
    case 'reports':
      return 'Report Downloads';
    case 'report':
      return '1 Report Download';
    case 'background_check':
      return 'Background checks';
    default:
      return feature;
  }
}

export const getFeatureNameFromTitle = (title: string): string => {
  if (title.includes('Background')) return 'background_check';
  if (title.includes('Social Networks')) return 'social_networks';
  if (title.includes('PDF')) return 'pdf_reports';
  if (title.includes('Offenders')) return 'offenders_package';
  return '';
};
