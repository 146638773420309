import type { ProductInfo } from '#types/prices.ts';
import styles from './Prices.module.scss';
import Typography from '#components/shared/ui/Typography';
import { capitalizeFirstLetter } from '#utils/string.ts';
import { SubscriptionIncludesBlock } from '#components/shared/sections/Prices/SubscriptionIncludesBlock.tsx';
import type { FC } from 'react';
import type { WithTranslations } from '#i18n/index.ts';
import { TRIAL7 } from '#constants/packages.ts';
import { getFeatureNameFromTitle } from '#utils/featureName.ts';
import LoadingButton from '#components/shared/ui/LoadingButton';

type Props = {
  card: ProductInfo;
  fullInfo?: {
    symbol: string;
    currency: string;
  };
  product: string;
  discounted?: string;
  popular?: string;
  vip?: string;
  discount?: number;
  isSubmitDisabled: boolean;
  onSubmit:
    | ((params: { product: string; funnelPlanTitle: string }) => void)
    | ((params: { product: string; featureName: string }) => void);
};

const PricingPlanCard: FC<WithTranslations<Props>> = ({
  t,
  card,
  product,
  discounted,
  discount,
  vip,
  fullInfo,
  onSubmit,
  isSubmitDisabled,
}) => {
  const { price, title, features, periodType, packageType, searchLimit } = card;
  const { monthlyAmount, totalAmount } = price || {};
  const isDiscounted = product === discounted;
  const isTrial = product === TRIAL7;
  const isVip = product === vip;

  const periodLabel = (period: string): string => {
    switch (period) {
      case 'day':
        return 'once';
      default:
        return 'monthly';
    }
  };

  const durationPlanLabel = (): string => {
    switch (product) {
      case TRIAL7:
        return t.common.prices.days7;
      default:
        return t.common.prices.month1;
    }
  };

  const getPackageName = (title: string): string => {
    switch (title) {
      case '1 month plan':
        return capitalizeFirstLetter(packageType);
      case '1 months Extreme':
        return capitalizeFirstLetter(packageType);
      case 'Trial':
        return t.common.prices.trialTitle;
      default:
        return title;
    }
  };

  return (
    <div className={styles.priceCardWrapper}>
      <div className={styles.priceCard}>
        {(isDiscounted || isTrial || isVip) && (
          <div className={`${styles.label} ${isVip && styles.inactiveLabel}`}>
            {isVip && !isDiscounted && t.common.prices.vip}
            {isDiscounted && `${discount}${t.common.prices.discountApplied}`}
            {isTrial && t.common.prices.bestChoice}
          </div>
        )}
        <div className={styles.titleWrapper}>
          <div className={styles.flexRow}>
            <Typography
              component="p"
              variant="h4"
              color="darkGreen"
              className={styles.packageTitle}
            >
              {getPackageName(title.en)}
            </Typography>
          </div>
          <div className={styles.flexRow}>
            <div className={styles.priceBlock}>
              <Typography component="span" variant="subtitle1" color="secondary">
                {durationPlanLabel()}
              </Typography>
              <Typography component="span" variant="subtitle1" color="lightGreen">
                {' / '}
              </Typography>
              <Typography component="span" variant="subtitle1" color="secondary">
                {fullInfo?.symbol} {Number(isTrial ? totalAmount : monthlyAmount)?.toFixed(2)}
              </Typography>
            </div>
            <div className={styles.flexRow}>
              <Typography
                component="p"
                variant="capture"
                color="lightGreen"
                className={styles.periodTitle}
              >
                {t.common.prices.billed} {periodLabel(periodType)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.flexRow}>
          <LoadingButton
            size="lg"
            variant="filled"
            className={`${styles.getStartedBtn} ${isSubmitDisabled && styles.disabledBtn}`}
            disabled={isSubmitDisabled}
            isLoading={isSubmitDisabled}
            onClick={() =>
              onSubmit({
                product,
                funnelPlanTitle: getPackageName(title.en),
                featureName: getFeatureNameFromTitle(title.en),
              })
            }
          >
            {t.common.prices.getStarted}
          </LoadingButton>
        </div>
        <div className={`${styles.flexRow}${styles.subsBlock}`}>
          <SubscriptionIncludesBlock
            t={t}
            features={features}
            periodType={periodType}
            searchLimit={searchLimit}
          />
        </div>
      </div>
    </div>
  );
};
export default PricingPlanCard;
