import styles from '#components/shared/sections/Prices/Prices.module.scss';
import Typography from '#components/shared/ui/Typography';
import React from 'react';

export const SubscriptionFeatureLi = ({ text }: { text: string }) => {
  return (
    <div className={styles.flexRow}>
      <img width={16} height={16} src="/icon/check.svg" alt="Check" />
      <Typography variant="body2" component="p" color="darkGreen" className={styles.feature}>
        {text}
      </Typography>
    </div>
  );
};
