import React from 'react';
import Typography from '#components/shared/ui/Typography';
import styles from '#components/shared/sections/Prices/Prices.module.scss';

type ConfidentialInfoBlockProps = {
  text: string;
};

export const ConfidentialInfoBlock = ({ text }: ConfidentialInfoBlockProps) => (
  <div className={styles.flexRow}>
    <img src="/icon/lock.svg" alt="lock" className={styles.lockIcon} />
    <Typography component="span" variant="capture" color="lightGreen">
      {text}
    </Typography>
  </div>
);
