import React, { type FC } from 'react';
import { getFeatureName } from '#utils/featureName.ts';
import Typography from '#components/shared/ui/Typography';
import styles from './Prices.module.scss';
import type { WithTranslations } from '#i18n/index.ts';
import type { FeaturesInfo } from '#types/prices.ts';
import { SubscriptionFeatureLi } from '#components/shared/sections/Prices/SubscriptionFeatureLi.tsx';

type Props = {
  features: FeaturesInfo;
  periodType: string;
  searchLimit: number;
};

export const SubscriptionIncludesBlock: FC<WithTranslations<Props>> = ({
  t,
  features,
  periodType,
  searchLimit,
}) => {
  const { upsellCounts } = features ?? {};
  const getFeature = (feature: string): string => {
    const isKnownFeature = ['reports', 'background_check'].includes(feature);
    return isKnownFeature ? `${upsellCounts?.[feature]} ${getFeatureName(feature)}` : feature;
  };

  return (
    <>
      <div className={styles.flexRow}>
        <Typography component="span" variant="capture" className={styles.mB8} color="lightGreen">
          {t.common.prices.includesSubs}
        </Typography>
      </div>
      {searchLimit && (
        <SubscriptionFeatureLi
          text={getFeature(`${searchLimit} searches ${periodType === 'month' ? 'monthly' : ''}`)}
        />
      )}
      {features?.enabled
        ?.filter((item: string) => item === 'reports' || item === 'background_check')
        .map((feature: string, index: number) => (
          <SubscriptionFeatureLi text={getFeature(feature)} key={index} />
        ))}
    </>
  );
};
