export const pricePackagesConfig = {
  trial7: {
    packages: ['SQLPM', 'SQLXM'],
    order: ['TRIAL7', 'SQLPM', 'SQLXM'],
    isTrial: 'TRIAL7',
    isDiscounted: '',
    vip: 'SQLXM',
    discount: 0,
    currency: 'USD',
  },
};

export const TRIAL7 = 'TRIAL7';
