import styles from './LoadingButton.module.scss';
import type { ComponentProps, FC } from 'react';
import CircularLoader from '#components/shared/ui/CircularLoader';
import Button, { type ButtonCommonProps } from '#components/shared/ui/Button';

type LoadingButtonProps = ButtonCommonProps & ComponentProps<'button'> & { isLoading: boolean };

const LoadingButton: FC<LoadingButtonProps> = ({ children, className, ...props }) => {
  const classes = [
    styles[(props.isLoading ? 'outlined' : props.variant) ?? 'transparent'],
    props.isLoading && styles['loading'],
    className ?? '',
  ];
  const classNames = classes.join(' ').trim();

  return (
    <Button className={classNames} {...props}>
      {props.isLoading ? <CircularLoader /> : children}
    </Button>
  );
};

export default LoadingButton;
